import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { dispatch, store } from "../redux/store";
import ReactLoading from "react-loading";
const HomePage = () => {
  const hasFetched = useRef(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [hash, setHash] = useState();
  const [order_id, setOrder_id] = useState();

  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(true);

  const orderID = searchParams.get("order_id");

  const merchant_id = searchParams.get("merchant_id");
  const user_id = searchParams.get("user_id");
  const cab_id = searchParams.get("cab_id");
  const return_url = searchParams.get("return_url");
  const cancel_url = searchParams.get("cancel_url");
  const notify_url = searchParams.get("notify_url");
  const items = searchParams.get("items");
  const payment_type = searchParams.get("payment_type");
  const currency = "LKR";
  const amount = searchParams.get("amount");
  const first_name = searchParams.get("first_name");
  const last_name = searchParams.get("last_name");
  const email = searchParams.get("email");
  const phone = searchParams.get("phone");
  const address = searchParams.get("address");
  const city = searchParams.get("city");
  const country = "Sri Lanka";
  const refType = searchParams.get("refType");
  const tripId = searchParams.get("tripId");
  const environment = searchParams.get("environment");

  // const requiredFields = ['merchant_id', 'return_url', 'cancel_url', 'notify_url', 'order_id', 'currency', 'amount', 'first_name', 'last_name', 'email', 'phone', 'address', 'city', 'country'];

  const data = {
    merchant_id: merchant_id ? merchant_id : undefined,
    return_url: return_url ? return_url : undefined,
    cancel_url: cancel_url ? cancel_url : undefined,
    notify_url: notify_url ? notify_url : undefined,
    items: items ? items : undefined,
    currency: currency,
    amount: amount ? parseFloat(amount).toFixed(2) : undefined,
    first_name: first_name ? first_name : undefined,
    last_name: last_name ? last_name : "not_updated",
    email: email ? email : "default@mail.com",
    phone: phone ? phone : undefined,
    address: address ? address : undefined,
    city: city ? city : undefined,
    country: country,
    // payment_type: payment_type ? payment_type : undefined,
  };

  function triggerFormAction() {
    console.log("form action triggered");
    document.getElementById("payment_form").submit();
  }

  async function getOrderDetails(order_id) {
    try {
      let environment = await localStorage.getItem("environment");
      const response = await axios.get(
        `https://${
          environment !== "prod" ? `${environment}-` : ""
        }payment-service.oxhorn.co/payHere/token/get?order_id=${order_id}`
      );
      console.log("response from payhere", response);
      if (response?.data?.status === 1) {
        updateUrl();
      } else {
        cancelledPaymentUpdate();
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function getHash() {
    try {
      const res = await axios.post(
        `https://${
          environment !== "prod" ? `${environment}-` : ""
        }payment-service.oxhorn.co/payment`,
        {
          userId: parseInt(user_id),
          cabId: cab_id ? parseInt(cab_id) : null,
          amount: parseFloat(amount),
          refType: refType,
          refId: tripId ? tripId : null,
        }
      );
      localStorage.setItem("transactionId", res.data.refId);
      localStorage.setItem("orderid", res.data.orderId);
      localStorage.setItem("environment", environment);
      setHash(res.data.hashCode);
      setOrder_id(res.data.orderId);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  }
  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    if ((user_id, cab_id, amount, refType)) getHash();
  }, []); // Ensures this runs only on mount and unmount

  useEffect(() => {
    Object.keys(data).map((i) => {
      if (typeof data[i] === "undefined") {
        setIsValid(false);
      }
    });

    console.log("first");

    if (isValid && hash) {
      // axios.post('https://www.payhere.lk/pay/checkout', data).then((res) => {
      //   console.log(res)
      // }).catch((err) => {
      //   console.log(err)
      // });
      // fetch("https://www.payhere.lk/pay/checkout", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     ...data,
      //     hash: "89DD42774E2E8C926379F23F06EDF8DF",
      //   }),
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      triggerFormAction();
    }
  }, [isValid, hash]);

  async function getUpdatedUrl() {
    let orderID = await localStorage.getItem("orderid");
    let transactionId = await localStorage.getItem("transactionId");
    if (orderID && transactionId) {
      const newUrl = `${window.location.pathname}?order_id=${orderID}&transaction_id=${transactionId}`;
      return newUrl;
    }
  }

  async function getCancelledUrl() {
    const newUrl = `${window.location.pathname}?cancelled=true`;
    return newUrl;
  }

  async function updateFailedUrl() {
    const newUrl = await getCancelledUrl();
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  async function updateUrl() {
    const newUrl = await getUpdatedUrl();
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  async function cancelledPaymentUpdate() {
    let orderID = await localStorage.getItem("orderid");
    let transactionId = await localStorage.getItem("transactionId");
    let environment = await localStorage.getItem("environment");
    console.log("inside function");
    if (orderID && transactionId) {
      console.log("inside condition");
      axios
        .put(
          `https://${
            environment !== "prod" ? `${environment}-` : ""
          }payment-service.oxhorn.co/payment/${transactionId}`,
          {
            orderId: orderID,
            onlineTransactionStatus: "CANCELED",
          }
        )
        .then((i) => {
          console.log(i);
          if (i?.data?.success) {
            updateFailedUrl();
          }
        });
    }
  }

  useEffect(() => {
    if (orderID) {
      console.log("sdasdasdda");
      getOrderDetails(orderID);
    }
  }, [orderID]);

  useEffect(() => {
    setTimeout(() => {
      if (
        window.location.href?.indexOf("?") === -1 &&
        window.location.href?.indexOf("?") < window.location.href?.length &&
        !window.location.href?.includes("payhere.lk")
      ) {
        cancelledPaymentUpdate();
      }
    }, 500);
  }, []);

  if (loading) {
    return (
      <div
        style={{
          height: window.screen.height,
          backgroundColor: "#dfe3e8",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          display: "flex",
        }}
      >
        <ReactLoading type="spin" color="#ADEE37" height={100} width={50} />
      </div>
    );
  }
  return (
    <>
      <div style={{ display: "none" }}>
        <form
          id="payment_form"
          method="post"
          action="https://www.payhere.lk/pay/checkout"
        >
          <input
            type="hidden"
            name="merchant_id"
            value={searchParams.get("merchant_id")}
          />
          <input
            type="hidden"
            name="return_url"
            value={searchParams.get("return_url")}
          />
          <input
            type="hidden"
            name="cancel_url"
            value={searchParams.get("cancel_url")}
          />
          <input
            type="hidden"
            name="notify_url"
            value={searchParams.get("notify_url")}
          />
          <br />
          <br />
          Item Details
          <br />
          <input type="text" name="order_id" value={order_id} />
          <input type="text" name="items" value={searchParams.get("items")} />
          <input type="text" name="currency" value="LKR" />
          <input
            type="text"
            name="amount"
            value={parseFloat(searchParams.get("amount")).toFixed(2)}
          />
          <br />
          <br />
          Customer Details
          <br />
          <input
            type="text"
            name="first_name"
            value={searchParams.get("first_name")}
          />
          <input
            type="text"
            name="last_name"
            value={searchParams.get("last_name") ?? "not_updated"}
          />
          <input
            type="text"
            name="email"
            value={searchParams.get("email") ?? "default@mail.com"}
          />
          <input type="text" name="phone" value={searchParams.get("phone")} />
          <input
            type="text"
            name="address"
            value={searchParams.get("address")}
          />
          <input type="text" name="city" value={searchParams.get("city")} />
          <input type="hidden" name="country" value="Sri Lanka" />
          <input type="hidden" name="hash" value={hash} />
          <input type="submit" value="Buy Now" />
        </form>
      </div>
    </>
  );
};

export default HomePage;
