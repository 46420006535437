import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import { rootReducer } from "./rootReducer";
import { persistStore } from "redux-persist";

const store = configureStore({
  reducer: rootReducer,
});

const persistor = persistStore(store);

const useSelector = useReduxSelector;
const useDispatch = () => useReduxDispatch();

const { dispatch } = store;
export {useDispatch, useSelector, store, dispatch, persistor}