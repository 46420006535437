import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refId: null,
};

const slice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setReferenceId: (state, action) => {
      state.refId = action.payload;
    },
  },
});

export const { setReferenceId } = slice.actions;
export default slice.reducer;
